// extracted by mini-css-extract-plugin
export const column = "UsedcarFooter-module--column--2kBSn";
export const band = "UsedcarFooter-module--band--1v6Tj";
export const isFixedWidthButton2 = "UsedcarFooter-module--is-fixed-width-button-2--31veM";
export const hasBackgroundLinecolor = "UsedcarFooter-module--has-background-linecolor--3-v-G";
export const footerLinkListWrapper = "UsedcarFooter-module--footer-link-list-wrapper--1qX_G";
export const isLinks = "UsedcarFooter-module--is-links--Kmhbx";
export const footerLogoArea = "UsedcarFooter-module--footer-logo-area--1v1PI";
export const isFixedWidthButtonNurturing = "UsedcarFooter-module--is-fixed-width-button-nurturing--1GKAO";
export const footerLinkListContainer = "UsedcarFooter-module--footer-link-list-container--qdRDD";
export const isForce = "UsedcarFooter-module--is-force--2RbGz";
export const isFirst = "UsedcarFooter-module--is-first--KXimD";
export const footerLinkListItem = "UsedcarFooter-module--footer-link-list-item--3DnhB";
export const isOrderThird = "UsedcarFooter-module--is-order-third--33fnS";
export const isFreedial = "UsedcarFooter-module--is-freedial--gKK4a";